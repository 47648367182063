import Header from "../header/Header";
import styles from "../../CSS/Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { urlTypeVal } from "../../../constants/constants";
import Transaction from "../../../components/Transaction/Transaction";
const Layout = () => {
  const [urlType, setUrlType] = useState("");
  useEffect(() => {
    function identifyUrl(url) {
      try {
        const parsedUrl = new URL(url); // Parse the URL
        const queryParams = parsedUrl.searchParams; // Extract query parameters
        console.log(
          queryParams.get("isReportingHub"),
          queryParams.get("isCRA")
        );
        // Check for specific query parameters
        if (
          queryParams.has("isReportingHub") &&
          queryParams.get("isReportingHub") === "true"
        ) {
          return urlTypeVal.rh;
        } else if (
          queryParams.has("isCRA") &&
          queryParams.get("isCRA") === "true"
        ) {
          return urlTypeVal.noRh;
        } else {
          return urlTypeVal.unknown;
        }
      } catch (error) {
        console.error("Invalid URL:", error.message);
        return urlTypeVal.unknown;
      }
    }
    const currentUrl = window.location.href;
    setUrlType(identifyUrl(currentUrl));
  }, []);
  return (
    <div>
      <div className={`${styles.LayoutShareChat} bgColorPrimary`}>
        <Header url={urlType} />
        <Transaction url={urlType} />
      </div>
    </div>
  );
};

export default Layout;
